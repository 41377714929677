import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 80%;
  max-width: 40em;
`;

export default function Videography(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 225.71 156.64"
    >
      <path
        class="cls-1"
        d="M22.76,81.31l19.67-4.4.27,1.2-5.79,1.3,8,35.85,2-.44a10.07,10.07,0,0,0,6.77-4.5,34.67,34.67,0,0,0,3.65-10l1.14-.25,1.83,13-29,6.47-.27-1.2,3.23-.72-8-35.85L23,82.51Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M56.84,86.54l13.39-3,5.6,25,3.06-.69.22,1-16.23,3.63-.22-1,3-.67-5.37-24-3.23.72Zm7.41-5.62c-1.93.44-3.42.25-4.45-.55a5.59,5.59,0,0,1-2-3.39,5.15,5.15,0,0,1,.53-3.9A5.43,5.43,0,0,1,62,70.75a6.16,6.16,0,0,1,4.44.35,4.79,4.79,0,0,1,2.24,3.35,5.39,5.39,0,0,1-.51,4.1A5.83,5.83,0,0,1,64.25,80.92Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M94.9,75.05l1.32-1.27c0-1.18-.6-1.65-1.69-1.41C93,72.73,92.45,74.1,93,76.51A17,17,0,0,0,94.1,79.7c3.19,1.24,5.1,3.31,5.75,6.2a8.36,8.36,0,0,1-1.5,7.43c-1.64,2-4.18,3.45-7.6,4.22a24,24,0,0,1-4.53.55c-1.37,1.38-2,2.33-1.89,2.84s1.34.5,3.67,0l5.58-1.25Q106,96.89,108,105.81a9.25,9.25,0,0,1-2,8.46q-3.11,3.65-11,5.42-13.5,3-14.81-2.83c-.47-2.11.6-3.88,3.23-5.31l3.61.74a11.54,11.54,0,0,0,.23,3.11q1.05,4.69,8,3.13a11.69,11.69,0,0,0,6.2-3,4.77,4.77,0,0,0,1.42-4.45c-.35-1.57-1-2.51-2.1-2.83s-3-.17-5.72.45l-5.3,1.18c-2.95.66-5.18.64-6.67,0a4.57,4.57,0,0,1-2.73-3.21,5.2,5.2,0,0,1,.56-3.91A25.44,25.44,0,0,1,84.94,98q-6.75-.39-8.2-6.89a8.47,8.47,0,0,1,1.47-7.27q2.39-3.18,8.29-4.5a12.91,12.91,0,0,1,6.37,0,13.18,13.18,0,0,1-.92-2.6q-.69-3.06.65-4.79A5.71,5.71,0,0,1,96,69.76a4.89,4.89,0,0,1,3.49.34,3.77,3.77,0,0,1,1.89,2.65,3.69,3.69,0,0,1-.37,3,3.48,3.48,0,0,1-2.18,1.43,3.63,3.63,0,0,1-2.46-.22A2.79,2.79,0,0,1,94.9,75.05ZM85.6,87.86l.66,3A13.21,13.21,0,0,0,88,95.64a2.08,2.08,0,0,0,2.2.89,2,2,0,0,0,1.57-1.71c.2-.94,0-2.74-.6-5.4l-.6-2.67a18.31,18.31,0,0,0-1.8-5.48,1.9,1.9,0,0,0-2.13-.93A2.13,2.13,0,0,0,85,82.16Q84.67,83.71,85.6,87.86Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M98.85,61.42,112,58.48l4.47,20a9.29,9.29,0,0,1,1.78-4.64q1.45-1.94,5.12-2.75,8.47-1.91,10.51,7.23l3.68,16.45,3.06-.69.22,1L125.4,98.53l-.22-1,2.24-.5-4-17.76a12.78,12.78,0,0,0-1.38-4.14,1.63,1.63,0,0,0-1.8-.6c-1.17.26-2,1.26-2.57,3a11.23,11.23,0,0,0-.1,5.87L121,98.48l2.35-.52.22,1L108,102.41l-.22-1,3-.67-8.72-39-3,.67Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M141,86.83l-3.85-17.21-3,.67-.22-1,3-.68-1.57-7L145,56.8l2.14,9.56,7.1-1.59.22,1-7.1,1.58,4.12,18.42a10.35,10.35,0,0,0,1.36,3.66,2.17,2.17,0,0,0,2.43.72,3.66,3.66,0,0,0,2.5-2.29,9.65,9.65,0,0,0,.61-4.84l.95-.1a10.91,10.91,0,0,1-1.06,6.53c-1,1.77-3,3-6,3.66s-5.47.6-7.35-.25S141.7,90,141,86.83Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M162.5,90.8,160.32,81l.65-.15q2.49,4.44,5.32,5.81a9.55,9.55,0,0,0,6.44.57q5.58-1.25,4.78-4.8a2.55,2.55,0,0,0-1.88-2,19,19,0,0,0-5.88-.63,21.8,21.8,0,0,1-6.82-1.23c-2.3-.87-3.76-2.72-4.4-5.56a9.28,9.28,0,0,1,1.11-7.39,9.88,9.88,0,0,1,6.42-4.11,14,14,0,0,1,5.8,0,4.87,4.87,0,0,0,1.69.08,1.31,1.31,0,0,0,.84-.59,6.45,6.45,0,0,0,.61-1.63l.6-.13,1.87,8.36-.65.14q-4.38-6.48-10.23-5.17A6.7,6.7,0,0,0,163,64.39a2.76,2.76,0,0,0-.75,2.55,2,2,0,0,0,.67,1.2,8.73,8.73,0,0,0,.75.54,2.65,2.65,0,0,0,.82.31c.36.09.66.16.9.2s.6.09,1.09.13a9.88,9.88,0,0,0,1.07.05,42.08,42.08,0,0,1,5.72.41,18.24,18.24,0,0,1,3.49,1c2.31.94,3.81,2.94,4.5,6s.3,5.62-1.17,7.61a11.3,11.3,0,0,1-6.95,4.05,14.74,14.74,0,0,1-6.92-.12,2.13,2.13,0,0,0-1.13-.2c-.8.18-1.44,1-1.9,2.55Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M191.19,84.39a4.52,4.52,0,0,1-3.86-.6,5.26,5.26,0,0,1-1.94-3.13,5,5,0,0,1,.48-3.63A4.55,4.55,0,0,1,189,74.88a5.36,5.36,0,0,1,3.81.36,4.45,4.45,0,0,1,2.08,3.15,5.17,5.17,0,0,1-.43,3.88A4.81,4.81,0,0,1,191.19,84.39Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M54.05,124q-8.46,1.9-4.81,18.24l1,4.31q1.74,7.77,5,11.66t7.6,2.92q6-1.33,8.42-14.16l1-.22L74.51,159l-.6.14a3.67,3.67,0,0,0-.79-.72,1.19,1.19,0,0,0-.85-.06,31.05,31.05,0,0,0-3.77,1.73,29.83,29.83,0,0,1-6.81,2.41q-8.91,2-14.87-1.72t-8.13-13.37q-2.14-9.65,1.78-16T53,123.09a25.86,25.86,0,0,1,6.72-.61,27.31,27.31,0,0,0,3.94,0,1.22,1.22,0,0,0,.76-.43,3.33,3.33,0,0,0,.41-1l.6-.14,3.09,11.76-1,.22a34.08,34.08,0,0,0-6.94-7.31A8,8,0,0,0,54.05,124Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M87.86,142.62l1.59-.36-.94-4.2a28.34,28.34,0,0,0-2.13-6.84,2.27,2.27,0,0,0-2.62-1.28,3.21,3.21,0,0,0-1.65.91,1.66,1.66,0,0,0-.48,1.6,5.47,5.47,0,0,0,.74,1.76l1.36-.31a7.83,7.83,0,0,1,1.06,2.66,3.69,3.69,0,0,1-.74,3.1,5.27,5.27,0,0,1-3.19,1.94q-4.69,1.05-5.71-3.48-1.5-6.74,9.92-9.28,6.51-1.45,9.48.2t4.31,7.56l2.48,11.1c.42,1.89,1.16,2.72,2.22,2.48,1.24-.27,1.54-2.31.92-6.11l.84-.13q.86,4.86-.33,7c-.79,1.44-2.38,2.43-4.79,3q-6.56,1.47-8.44-2.36a6.09,6.09,0,0,1-1.45,3.54A7.46,7.46,0,0,1,86.38,157q-8.1,1.81-9.57-4.8-.87-3.89,1.9-6T87.86,142.62Zm.44,10.8a1.16,1.16,0,0,0,1.33.57c.57-.13,1-.71,1.27-1.75a8.41,8.41,0,0,0-.12-4l-1.14-5.08-.38.08Q85.87,144,87,149l.19.88A13.79,13.79,0,0,0,88.3,153.42Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M101.24,125.8l13.17-3,1.13,5c.37-4,2.6-6.44,6.7-7.35s7,.49,8.78,4.23q.6-6.33,6.72-7.7,8-1.78,10.07,7.33l3.68,16.45,3.06-.68.22,1-15.14,3.39-.22-1,1.91-.42-4-17.77q-.93-4.14-1.8-4.7a1.19,1.19,0,0,0-.94-.13c-1.06.23-1.83,1.15-2.31,2.75a11.1,11.1,0,0,0,0,5.68l3.45,15.41,2-.44.22,1-14,3.14-.22-1,1.91-.42-4-17.77q-.93-4.14-1.8-4.7a1.19,1.19,0,0,0-.94-.13c-1,.23-1.77,1.21-2.24,2.94a11.92,11.92,0,0,0,0,5.82L120,147.88l2-.44.22,1-15.14,3.38-.22-1,3-.67-5.37-24-3,.67Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M157.46,137.3q-4.34-2.85-5.78-9.32t1.74-10.81a15.25,15.25,0,0,1,9.31-5.7q12.41-2.76,14.7,9.8L162,124.72l.48,2.13c.8,3.57,1.85,6.16,3.17,7.78A5,5,0,0,0,171,136.3q6.33-1.41,6.79-8.63l1-.06a11.59,11.59,0,0,1-2.38,7q-2.21,2.79-7.62,4Q161.8,140.15,157.46,137.3Zm4.37-13.69,5.63-1.26-.6-2.68a23.51,23.51,0,0,0-1.87-5.92,2.11,2.11,0,0,0-2.44-1.15,2.52,2.52,0,0,0-1.91,2.24,17.33,17.33,0,0,0,.59,6.09Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M194,106.66c-1.35.31-2.33,1.45-3,3.42a12.41,12.41,0,0,0-.14,6.57l3.27,14.65,3.88-.87.22,1-17,3.81-.22-1,3-.68-5.38-24-3,.67-.22-1,13.17-3,1.17,5.25a7.53,7.53,0,0,1,6-7.43,6.15,6.15,0,0,1,4.5.51,5.35,5.35,0,0,1,2.53,3.74,6.26,6.26,0,0,1-.29,4.34,4.65,4.65,0,0,1-3.37,2.24,4.28,4.28,0,0,1-4-.82q-1.55-1.36-1.31-4.46l2-.44C196.26,107.13,195.67,106.29,194,106.66Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M217.59,113.61l1.59-.35-.94-4.21a27.92,27.92,0,0,0-2.14-6.84,2.25,2.25,0,0,0-2.61-1.28,3.24,3.24,0,0,0-1.66.91,1.65,1.65,0,0,0-.47,1.6,5.47,5.47,0,0,0,.74,1.76l1.36-.31a8,8,0,0,1,1.06,2.67,3.65,3.65,0,0,1-.75,3.09,5.17,5.17,0,0,1-3.18,1.94q-4.69,1.05-5.71-3.48-1.5-6.72,9.92-9.28,6.49-1.45,9.48.2t4.3,7.56l2.48,11.1c.43,1.89,1.17,2.72,2.23,2.49q1.85-.42.92-6.12l.83-.13q.87,4.86-.32,7c-.79,1.43-2.38,2.42-4.79,3q-6.56,1.47-8.45-2.36a6,6,0,0,1-1.44,3.54A7.46,7.46,0,0,1,216.1,128q-8.08,1.81-9.56-4.8-.87-3.89,1.89-6T217.59,113.61Zm.44,10.81a1.16,1.16,0,0,0,1.33.56c.56-.13,1-.71,1.27-1.75a8.41,8.41,0,0,0-.12-4l-1.14-5.08-.38.09q-3.39.75-2.28,5.73l.2.87A13.91,13.91,0,0,0,218,124.42Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M244.54,121.64a4.45,4.45,0,0,1-3.86-.6,5.19,5.19,0,0,1-1.93-3.12,4.94,4.94,0,0,1,.48-3.64,4.52,4.52,0,0,1,3.16-2.14,5.36,5.36,0,0,1,3.81.35,4.42,4.42,0,0,1,2.08,3.15,5.21,5.21,0,0,1-.42,3.88A4.82,4.82,0,0,1,244.54,121.64Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M74.49,207.19l-6.63-10.91L57,198.7l-1.57,12.74,4.1-.92.23,1-8.36,1.87-.23-1,2.57-.57,5.54-40.66,4.48-1L86,204.62l2.51-.56.23,1-17.87,4-.23-1ZM59.2,181.92l-1.94,15.46,9.89-2.21Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M97.29,176.32a3.66,3.66,0,0,0-2.85,3.4c-.3,1.9,0,4.88.9,8.92l.64,2.84q1.2,5.42,2.94,7.49t5,1.36a6.65,6.65,0,0,0,4.65-3.33,10.87,10.87,0,0,0,1.34-5.52l1-.12q0,4.94-2.31,7.64c-1.58,1.84-4,3.13-7.34,3.88q-6.33,1.41-10.49-1.25t-5.62-9.24q-1.48-6.58,1.58-11t10-5.95c3.24-.73,5.86-.65,7.85.22a5.85,5.85,0,0,1,3.63,4.15,6.11,6.11,0,0,1-.3,4.43,4.54,4.54,0,0,1-3.1,2.07,5.17,5.17,0,0,1-3.73-.37,4.4,4.4,0,0,1-2.07-3.09,7.64,7.64,0,0,1-.16-2l1.8-.41a6.22,6.22,0,0,0-.06-2A2.38,2.38,0,0,0,97.29,176.32Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M114,191.24,110.15,174l-3,.67-.22-1,3-.67-1.57-7,9.6-4.79,2.14,9.57,7.1-1.59.22,1-7.1,1.59,4.12,18.42a10.2,10.2,0,0,0,1.36,3.65,2.14,2.14,0,0,0,2.43.72,3.62,3.62,0,0,0,2.5-2.28,9.78,9.78,0,0,0,.61-4.84l.95-.1a10.87,10.87,0,0,1-1.06,6.52q-1.48,2.66-6,3.67c-3,.68-5.47.59-7.35-.25S114.71,194.41,114,191.24Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M128.36,168.92l13.39-3,5.6,25,3.06-.69.22,1-16.23,3.63-.22-1,3-.67-5.37-24-3.23.72Zm7.41-5.62c-1.93.44-3.42.25-4.46-.55a5.62,5.62,0,0,1-2-3.39,5.14,5.14,0,0,1,.53-3.9,5.43,5.43,0,0,1,3.75-2.33,6.16,6.16,0,0,1,4.44.35,4.8,4.8,0,0,1,2.24,3.34,5.41,5.41,0,0,1-.51,4.11A5.83,5.83,0,0,1,135.77,163.3Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M153.69,187.47q-4.27-2.72-5.74-9.25t1.49-10.89c2-2.91,5.22-4.86,9.74-5.88s8.13-.67,10.83,1,4.56,4.72,5.56,9.17q3.1,13.94-10.77,17Q158,190.19,153.69,187.47Zm12-10.88-1-4.65a38.34,38.34,0,0,0-2.3-7.6c-.75-1.54-1.81-2.15-3.2-1.84a2.78,2.78,0,0,0-1.78,1.12,4.25,4.25,0,0,0-.56,2.5,42.53,42.53,0,0,0,1.18,7.6l.95,4.26a39.58,39.58,0,0,0,2,7.08,11.7,11.7,0,0,0,1.18,1.8,2.37,2.37,0,0,0,2.64.79c1.42-.32,2.17-1.34,2.24-3.08A39.45,39.45,0,0,0,165.66,176.59Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M173,158.93l13.17-3,1.13,5a9.34,9.34,0,0,1,1.77-4.64c1-1.29,2.68-2.21,5.12-2.75q8.47-1.91,10.52,7.23l3.67,16.45,3.07-.69.21,1-15.46,3.46-.22-1,2.24-.5-4-17.76a12.71,12.71,0,0,0-1.39-4.14,1.63,1.63,0,0,0-1.8-.6c-1.16.26-2,1.26-2.57,3a11.13,11.13,0,0,0-.09,5.87L191.81,181l2.35-.52.22,1-15.52,3.47-.22-1,3-.67-5.38-24-3,.67Z"
        transform="translate(-22.76 -56.8)"
      />
      <path
        class="cls-1"
        d="M218.6,176.63a4.54,4.54,0,0,1-3.87-.6,5.19,5.19,0,0,1-1.93-3.13,4.92,4.92,0,0,1,.48-3.63,4.51,4.51,0,0,1,3.16-2.14,5.3,5.3,0,0,1,3.81.35,4.46,4.46,0,0,1,2.09,3.15,5.17,5.17,0,0,1-.43,3.88A4.83,4.83,0,0,1,218.6,176.63Z"
        transform="translate(-22.76 -56.8)"
      />
    </Svg>
  );
}
