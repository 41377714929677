import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 80%;
  max-width: 22em;
`;

export default function ContactSVG(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 242.04 161.2"
    >
      <path
        class="cls-1"
        d="M107.23,42.13H124.7v1.06h-3.24V60.55H132.6V43.19h-3.3V42.13h17.52v1.06h-3.3V80.26h3.3v1.07H129.3V80.26h3.3V62H121.46V80.26h3.24v1.07H107.23V80.26h3.31V43.19h-3.31Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M161.1,68.17h1.63V63.85a28,28,0,0,0-.59-7.14,2.27,2.27,0,0,0-2.27-1.82,3.22,3.22,0,0,0-1.82.54,1.65,1.65,0,0,0-.81,1.45,5.73,5.73,0,0,0,.33,1.88H159a7.89,7.89,0,0,1,.45,2.83,3.62,3.62,0,0,1-1.4,2.85,5.23,5.23,0,0,1-3.53,1.21q-4.81,0-4.81-4.65,0-6.89,11.7-6.89,6.68,0,9.21,2.27t2.55,8.31V76.06c0,1.94.54,2.91,1.63,2.91,1.27,0,2-1.92,2.24-5.76l.84.05Q177.63,78.19,176,80c-1.08,1.23-2.86,1.85-5.32,1.85-4.48,0-7.06-1.39-7.73-4.15a6,6,0,0,1-2.18,3.14,7.49,7.49,0,0,1-4.26,1q-8.28,0-8.29-6.78,0-4,3.17-5.46C153.5,68.66,156.73,68.17,161.1,68.17Zm-1.93,10.64a1.17,1.17,0,0,0,1.18.84c.57,0,1.12-.48,1.62-1.43a8.58,8.58,0,0,0,.76-4V69.06h-.39q-3.48,0-3.48,5.1v.89A14.13,14.13,0,0,0,159.17,78.81Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M190.32,54.67v4.42a7.78,7.78,0,0,1,7.73-5,8.87,8.87,0,0,1,7.47,3.47q2.67,3.48,2.66,10.53T205.3,78.53q-2.88,3.36-8.65,3.36a9.34,9.34,0,0,1-4-.68,5.21,5.21,0,0,1-2.18-2.07V93.65h7.22v1H176.93v-1H180v-38h-3v-1Zm6.49,3.84c-.37-1.33-1.1-2-2.18-2s-2,.7-2.91,2.1a10.67,10.67,0,0,0-1.29,5.63V74.49a8.22,8.22,0,0,0,.95,4.09,3,3,0,0,0,2.8,1.68q1.84,0,2.52-2.46a37.44,37.44,0,0,0,.67-8.63V66.26A34,34,0,0,0,196.81,58.51Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M222,54.67v4.42a7.78,7.78,0,0,1,7.73-5,8.87,8.87,0,0,1,7.47,3.47q2.66,3.48,2.66,10.53t-2.88,10.42q-2.9,3.36-8.65,3.36a9.34,9.34,0,0,1-4-.68,5.21,5.21,0,0,1-2.18-2.07V93.65h7.22v1H208.57v-1h3.08v-38h-3v-1Zm6.49,3.84c-.37-1.33-1.1-2-2.18-2s-2.05.7-2.91,2.1a10.67,10.67,0,0,0-1.29,5.63V74.49a8.22,8.22,0,0,0,1,4.09,3,3,0,0,0,2.8,1.68q1.85,0,2.52-2.46a37.44,37.44,0,0,0,.67-8.63V66.26A34,34,0,0,0,228.45,58.51Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M239.12,55.68v-1h16.24v1h-3l6.1,18.59,6.5-18.59h-3.75v-1h7.89v1h-2.8L255.81,85.86q-1.84,5.27-4.14,7.37a8,8,0,0,1-5.6,2.1,8.14,8.14,0,0,1-5.21-1.52A4.7,4.7,0,0,1,239,90a4.81,4.81,0,0,1,1.24-3.58,4.35,4.35,0,0,1,3.24-1.24q4.65,0,4.65,3.87a7.59,7.59,0,0,1-.33,2h-2a3.08,3.08,0,0,0-.12,2.13,1.45,1.45,0,0,0,1.52.84,5.12,5.12,0,0,0,4.08-2.27,24.1,24.1,0,0,0,3.42-6.91l1.23-3.47H251l-9.57-25.65Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M90.71,127.32V109.68H87.63v-1h3.08v-7.22l10.42-2.58v9.8h7.28v1h-7.28v18.87a10.45,10.45,0,0,0,.53,3.86,2.15,2.15,0,0,0,2.21,1.24,3.63,3.63,0,0,0,2.94-1.68,9.87,9.87,0,0,0,1.66-4.6l1,.12a10.84,10.84,0,0,1-2.47,6.13q-2,2.26-6.66,2.27T93.18,134Q90.71,132.2,90.71,127.32Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M113.74,132.3q-3.58-3.59-3.58-10.27T114,111.72q3.84-3.61,10.78-3.61t10.36,3.33q3.42,3.33,3.42,10.17,0,14.28-14.23,14.28Q117.33,135.89,113.74,132.3Zm14.06-8v-4.76a38.14,38.14,0,0,0-.59-7.92c-.39-1.66-1.3-2.49-2.72-2.49a2.81,2.81,0,0,0-2,.7,4.34,4.34,0,0,0-1.09,2.32,43.27,43.27,0,0,0-.5,7.67v4.37a40.3,40.3,0,0,0,.36,7.34,12.24,12.24,0,0,0,.76,2,2.38,2.38,0,0,0,2.41,1.35q2.18,0,2.85-2.52A38.35,38.35,0,0,0,127.8,124.29Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M150.2,93.33h13.49v20.49a9.22,9.22,0,0,1,2.75-4.14c1.23-1.05,3.09-1.57,5.6-1.57q8.67,0,8.68,9.35v16.86h3.13v1H168v-1h2.3v-18.2a12.85,12.85,0,0,0-.45-4.34,1.62,1.62,0,0,0-1.62-1c-1.2,0-2.25.78-3.17,2.35a11.2,11.2,0,0,0-1.37,5.71v15.46h2.41v1H150.2v-1h3.08v-40H150.2Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M187.26,132.16q-3.62-3.72-3.61-10.36t4.06-10.16A15.2,15.2,0,0,1,198,108.11q12.72,0,12.21,12.77H194.46v2.18c0,3.66.46,6.42,1.4,8.29a5,5,0,0,0,4.87,2.8q6.5,0,8.51-6.94l1,.16a11.61,11.61,0,0,1-3.84,6.28c-1.84,1.49-4.62,2.24-8.31,2.24Q190.87,135.89,187.26,132.16Zm7.25-12.4h5.77V117a23.82,23.82,0,0,0-.53-6.18,2.1,2.1,0,0,0-2.13-1.66,2.49,2.49,0,0,0-2.35,1.77,17.08,17.08,0,0,0-.76,6.07Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M224.8,122.17h1.63v-4.32a28,28,0,0,0-.59-7.14,2.27,2.27,0,0,0-2.27-1.82,3.22,3.22,0,0,0-1.82.54,1.65,1.65,0,0,0-.81,1.45,5.5,5.5,0,0,0,.34,1.88h1.4a8.13,8.13,0,0,1,.44,2.83,3.62,3.62,0,0,1-1.4,2.85,5.22,5.22,0,0,1-3.52,1.21c-3.22,0-4.82-1.55-4.82-4.65q0-6.89,11.7-6.89,6.67,0,9.22,2.27t2.54,8.31v11.37c0,1.94.54,2.91,1.63,2.91,1.27,0,2-1.92,2.24-5.76l.84.05q-.23,4.94-1.85,6.78c-1.08,1.23-2.86,1.85-5.32,1.85q-6.72,0-7.73-4.15a6,6,0,0,1-2.18,3.14,7.49,7.49,0,0,1-4.26,1q-8.28,0-8.29-6.78,0-4,3.17-5.46T224.8,122.17Zm-1.93,10.64a1.17,1.17,0,0,0,1.18.84c.58,0,1.12-.48,1.62-1.43a8.45,8.45,0,0,0,.76-3.95v-5.21H226q-3.48,0-3.48,5.1v.89A14.08,14.08,0,0,0,222.87,132.81Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M260.18,110.24c-1.38,0-2.6.89-3.64,2.69a12.39,12.39,0,0,0-1.57,6.38v15h4v1H241.47v-1h3.08V109.68h-3.08v-1H255v5.38a7.54,7.54,0,0,1,7.5-5.94,6.17,6.17,0,0,1,4.29,1.48,5.36,5.36,0,0,1,1.65,4.2,6.33,6.33,0,0,1-1.23,4.18,4.67,4.67,0,0,1-3.78,1.45,4.29,4.29,0,0,1-3.75-1.68q-1.22-1.68-.31-4.65h2Q262.76,110.24,260.18,110.24Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M44.18,147a2.7,2.7,0,0,0-2.8,1.6,14.11,14.11,0,0,0-.68,5.12v9h7.4v1H40.7v24.64h6.84v1H27.21v-1h3.08V163.68H27v-1h3.25v-.22q0-8.07,3.08-12.24T43.67,146q4.76,0,6.84,1.87a5.74,5.74,0,0,1,2.07,4.34,4.88,4.88,0,0,1-1.29,3.7,4.75,4.75,0,0,1-3.42,1.23,4.53,4.53,0,0,1-3.27-1.09,3.38,3.38,0,0,1-1.15-2.52,11.54,11.54,0,0,1,.33-2.77h2.13Q47,147,44.18,147Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M67.57,164.24c-1.38,0-2.59.89-3.64,2.69a12.49,12.49,0,0,0-1.57,6.38v15h4v1H48.87v-1H52V163.68H48.87v-1H62.36v5.38a7.56,7.56,0,0,1,7.51-5.94,6.16,6.16,0,0,1,4.28,1.48,5.36,5.36,0,0,1,1.65,4.2A6.32,6.32,0,0,1,74.57,172a4.67,4.67,0,0,1-3.78,1.45A4.29,4.29,0,0,1,67,171.74c-.8-1.12-.91-2.67-.31-4.65h2Q70.15,164.24,67.57,164.24Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M80,186.3Q76.41,182.71,76.4,176t3.84-10.31q3.84-3.61,10.78-3.61t10.36,3.33q3.42,3.33,3.42,10.17,0,14.28-14.23,14.28Q83.57,189.89,80,186.3Zm14.05-8v-4.76a38.3,38.3,0,0,0-.58-7.92c-.4-1.66-1.3-2.49-2.72-2.49a2.81,2.81,0,0,0-2,.7,4.27,4.27,0,0,0-1.09,2.32,42.32,42.32,0,0,0-.5,7.67v4.37a40.3,40.3,0,0,0,.36,7.34,11.4,11.4,0,0,0,.76,2,2.38,2.38,0,0,0,2.4,1.35c1.46,0,2.41-.84,2.86-2.52A38.61,38.61,0,0,0,94,178.29Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M105,162.67h13.49v5.15q1.86-5.72,8.15-5.71t7.65,6q2-6,8.23-6,8.23,0,8.23,9.35v16.86h3.14v1H138.37v-1h2v-18.2c0-2.84-.24-4.5-.72-5a1.25,1.25,0,0,0-.9-.33c-1.08,0-2,.73-2.86,2.18a11.23,11.23,0,0,0-1.23,5.55v15.79h2v1H122.25v-1h2v-18.2c0-2.84-.25-4.5-.73-5a1.25,1.25,0,0,0-.9-.33c-1,0-2,.79-2.83,2.38a12,12,0,0,0-1.26,5.68v15.46h2v1H105v-1h3.08V163.68H105Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M164.53,163.68v-1h16.23v1h-3l6.1,18.59,6.5-18.59h-3.75v-1h7.9v1h-2.8l-10.48,30.18q-1.85,5.26-4.14,7.37a8,8,0,0,1-5.6,2.1,8.16,8.16,0,0,1-5.21-1.52,4.7,4.7,0,0,1-1.9-3.86,4.84,4.84,0,0,1,1.23-3.58,4.36,4.36,0,0,1,3.25-1.24q4.65,0,4.65,3.87a7.65,7.65,0,0,1-.34,2h-2a3,3,0,0,0-.11,2.13,1.43,1.43,0,0,0,1.51.84,5.15,5.15,0,0,0,4.09-2.27,24.38,24.38,0,0,0,3.41-6.91l1.24-3.47H176.4l-9.58-25.65Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M196.28,186.3q-3.59-3.59-3.58-10.27t3.83-10.31q3.84-3.61,10.78-3.61t10.36,3.33q3.42,3.33,3.42,10.17,0,14.28-14.22,14.28Q199.86,189.89,196.28,186.3Zm14.06-8v-4.76a38.14,38.14,0,0,0-.59-7.92c-.39-1.66-1.3-2.49-2.72-2.49a2.77,2.77,0,0,0-2,.7,4.27,4.27,0,0,0-1.1,2.32,43.27,43.27,0,0,0-.5,7.67v4.37a40.3,40.3,0,0,0,.36,7.34,13.23,13.23,0,0,0,.76,2,2.38,2.38,0,0,0,2.41,1.35c1.45,0,2.41-.84,2.85-2.52A38.35,38.35,0,0,0,210.34,178.29Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M254.06,189.33H241.3l-.62-4.09a7.31,7.31,0,0,1-2.66,3.44,9.35,9.35,0,0,1-5.24,1.21q-8.67,0-8.68-9.36V163.68H221v-1h13.55v19.21a12.88,12.88,0,0,0,.48,4.42,1.87,1.87,0,0,0,1.9,1.07,3.13,3.13,0,0,0,2.55-1.74,8.9,8.9,0,0,0,1.12-4.82V163.68H238v-1h13v25.65h3.08Z"
        transform="translate(-27.04 -42.13)"
      />
      <path
        class="cls-1"
        d="M261.16,189.89a4.5,4.5,0,0,1-3.64-1.43,5.22,5.22,0,0,1-1.21-3.47,5,5,0,0,1,1.27-3.45,4.54,4.54,0,0,1,3.55-1.4,5.3,5.3,0,0,1,3.64,1.18,4.42,4.42,0,0,1,1.35,3.53,5.14,5.14,0,0,1-1.27,3.69A4.8,4.8,0,0,1,261.16,189.89Z"
        transform="translate(-27.04 -42.13)"
      />
    </Svg>
  );
}
