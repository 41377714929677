import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 100%;
  max-width: 18em;
  height: auto;
  padding: 0 1em 0 0;
`;

export default function ThisIsME(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187.75 138.56"
      {...props}
    >
      <path
        d="M52.28,23.47l.56,14.05H51.78q-2.46-7.44-4.37-10.16a6.3,6.3,0,0,0-5.49-2.72h-.67V61.49H46v1.18H25.57V61.49h4.76V24.64H29.6a6.35,6.35,0,0,0-5.54,2.83q-2,2.84-4.31,10.05H18.68l.56-14.05Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M53.62,20.67H67.11V41.16A9.34,9.34,0,0,1,69.85,37c1.24-1.05,3.1-1.57,5.61-1.57q8.67,0,8.68,9.35V61.66h3.13v1H71.42v-1h2.3V43.46a12.85,12.85,0,0,0-.45-4.34,1.63,1.63,0,0,0-1.62-1c-1.2,0-2.25.78-3.17,2.35a11.2,11.2,0,0,0-1.37,5.71V61.66h2.41v1H53.62v-1H56.7v-40H53.62Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M88,36h13.72V61.66h3.14v1H88.26v-1h3.08V37H88Zm8.46-3.86c-2,0-3.39-.51-4.23-1.52A5.63,5.63,0,0,1,91,26.88a5.18,5.18,0,0,1,1.37-3.69,5.49,5.49,0,0,1,4.17-1.46,6.14,6.14,0,0,1,4.26,1.32,4.78,4.78,0,0,1,1.46,3.75,5.39,5.39,0,0,1-1.4,3.89A5.83,5.83,0,0,1,96.49,32.15Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M106.6,63.23v-10h.67c1,3.25,2.27,5.53,3.92,6.83a9.55,9.55,0,0,0,6.16,2q5.72,0,5.71-3.64a2.55,2.55,0,0,0-1.4-2.35,19,19,0,0,0-5.6-1.9,21.73,21.73,0,0,1-6.38-2.69c-2.06-1.34-3.08-3.47-3.08-6.38a9.27,9.27,0,0,1,2.68-7,9.9,9.9,0,0,1,7.17-2.6,13.93,13.93,0,0,1,5.66,1.29,4.64,4.64,0,0,0,1.62.45,1.27,1.27,0,0,0,1-.4,6.58,6.58,0,0,0,1-1.45h.61v8.57h-.67q-2.85-7.29-8.85-7.28a6.73,6.73,0,0,0-3.92.92,2.77,2.77,0,0,0-1.29,2.32,2,2,0,0,0,.4,1.32c.26.32.46.55.61.7a2.86,2.86,0,0,0,.73.48c.34.16.62.3.84.39s.57.21,1,.36a7,7,0,0,0,1,.28,44.82,44.82,0,0,1,5.49,1.65,19.17,19.17,0,0,1,3.19,1.71q3.08,2.13,3.08,6.86t-2.8,7.17a11.31,11.31,0,0,1-7.67,2.44,14.73,14.73,0,0,1-6.72-1.63,2.15,2.15,0,0,0-1.06-.45c-.83,0-1.63.7-2.41,2.08Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M139.69,36h13.72V61.66h3.14v1H139.92v-1H143V37h-3.31Zm8.46-3.86c-2,0-3.39-.51-4.23-1.52a5.63,5.63,0,0,1-1.26-3.75A5.18,5.18,0,0,1,144,23.19a5.49,5.49,0,0,1,4.17-1.46,6.14,6.14,0,0,1,4.26,1.32,4.77,4.77,0,0,1,1.45,3.75,5.39,5.39,0,0,1-1.39,3.89A5.83,5.83,0,0,1,148.15,32.15Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M158.25,63.23v-10h.68c1,3.25,2.27,5.53,3.92,6.83A9.55,9.55,0,0,0,169,62c3.8,0,5.71-1.21,5.71-3.64a2.55,2.55,0,0,0-1.4-2.35,19,19,0,0,0-5.6-1.9,21.91,21.91,0,0,1-6.39-2.69q-3.08-2-3.08-6.38a9.28,9.28,0,0,1,2.69-7,9.88,9.88,0,0,1,7.17-2.6,14,14,0,0,1,5.66,1.29,4.59,4.59,0,0,0,1.62.45,1.27,1.27,0,0,0,1-.4,6.28,6.28,0,0,0,.95-1.45h.62v8.57h-.67q-2.87-7.29-8.85-7.28a6.71,6.71,0,0,0-3.92.92,2.77,2.77,0,0,0-1.29,2.32,2,2,0,0,0,.39,1.32c.26.32.47.55.62.7a2.86,2.86,0,0,0,.73.48c.33.16.61.3.84.39s.57.21,1,.36a7.34,7.34,0,0,0,1,.28,44.82,44.82,0,0,1,5.49,1.65,19.17,19.17,0,0,1,3.19,1.71q3.08,2.13,3.08,6.86t-2.8,7.17a11.31,11.31,0,0,1-7.67,2.44,14.73,14.73,0,0,1-6.72-1.63,2.18,2.18,0,0,0-1.07-.45c-.82,0-1.62.7-2.4,2.08Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M47.35,71.47h15v1.06H59.23V109.6h3.08v1.07H45.23V109.6h3.08v-35h-.23l-8.4,36.07h-7L22.83,74.43H22.6V109.6h3.08v1.07H18.07V109.6h3.08V72.53H18.07V71.47H33.19l7.44,28.84Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M64.23,84H78v25.65h3.13v1H64.45v-1h3.08V85h-3.3Zm8.45-3.86c-2,0-3.39-.51-4.23-1.52a5.63,5.63,0,0,1-1.26-3.75,5.15,5.15,0,0,1,1.38-3.69,5.46,5.46,0,0,1,4.17-1.46A6.13,6.13,0,0,1,77,71.05a4.78,4.78,0,0,1,1.46,3.75,5.39,5.39,0,0,1-1.4,3.89A5.82,5.82,0,0,1,72.68,80.15Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M100.9,85.58c-1.38,0-2.59.89-3.64,2.69a12.39,12.39,0,0,0-1.57,6.38v15h4v1H82.2v-1h3.08V85H82.2V84H95.69v5.38a7.54,7.54,0,0,1,7.51-5.94,6.2,6.2,0,0,1,4.28,1.48,5.36,5.36,0,0,1,1.65,4.2,6.3,6.3,0,0,1-1.23,4.18,4.67,4.67,0,0,1-3.78,1.45,4.29,4.29,0,0,1-3.75-1.68q-1.22-1.68-.31-4.65h2Q103.48,85.58,100.9,85.58Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M109.6,84h13.72v25.65h3.13v1H109.82v-1h3.08V85h-3.3Zm8.45-3.86c-2,0-3.39-.51-4.23-1.52a5.63,5.63,0,0,1-1.26-3.75,5.15,5.15,0,0,1,1.38-3.69,5.46,5.46,0,0,1,4.17-1.46,6.11,6.11,0,0,1,4.25,1.32,4.78,4.78,0,0,1,1.46,3.75,5.39,5.39,0,0,1-1.4,3.89A5.82,5.82,0,0,1,118.05,80.15Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M139.92,97.51h1.62V93.19a28.1,28.1,0,0,0-.58-7.14,2.28,2.28,0,0,0-2.27-1.82,3.22,3.22,0,0,0-1.82.54,1.65,1.65,0,0,0-.81,1.45,5.73,5.73,0,0,0,.33,1.88h1.4a7.93,7.93,0,0,1,.45,2.83,3.65,3.65,0,0,1-1.4,2.85A5.18,5.18,0,0,1,133.31,95q-4.81,0-4.81-4.65,0-6.89,11.7-6.89,6.66,0,9.21,2.27T152,94V105.4q0,2.91,1.62,2.91,1.9,0,2.24-5.76l.84,0q-.22,4.94-1.84,6.78t-5.32,1.85q-6.72,0-7.73-4.15a6.07,6.07,0,0,1-2.19,3.14,7.47,7.47,0,0,1-4.25,1q-8.3,0-8.29-6.78,0-4,3.16-5.46T139.92,97.51ZM138,108.15a1.15,1.15,0,0,0,1.17.84c.58,0,1.12-.48,1.63-1.43a8.59,8.59,0,0,0,.75-4V98.4h-.39q-3.47,0-3.47,5.1v.89A13.65,13.65,0,0,0,138,108.15Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M156.7,84h13.5v5.15q1.85-5.72,8.15-5.71t7.64,6q2-6,8.23-6,8.24,0,8.24,9.35v16.86h3.13v1H190.08v-1h2V91.46c0-2.84-.24-4.5-.73-5a1.21,1.21,0,0,0-.89-.33c-1.09,0-2,.73-2.86,2.18a11.23,11.23,0,0,0-1.23,5.55v15.79h2v1H174v-1h2V91.46c0-2.84-.24-4.5-.73-5a1.21,1.21,0,0,0-.89-.33c-1,0-2,.79-2.83,2.38a12,12,0,0,0-1.26,5.68v15.46h2v1H156.7v-1h3.08V85H156.7Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M17.84,119.47H47l.56,11.08h-1c-1.56-3.77-3.21-6.35-4.92-7.75s-4.24-2.1-7.56-2.1h-2V138H34a5.68,5.68,0,0,0,4.42-2,11.28,11.28,0,0,0,2.52-5.34h.84v16.57h-1a13.18,13.18,0,0,0-2.71-6,6.18,6.18,0,0,0-4.68-1.79H32.07v18h2.12A13,13,0,0,0,43,154.35a15.54,15.54,0,0,0,4.95-7.89H49l-.44,12.21H17.84v-1.24h3.31V120.7H17.84Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M71.29,142.15,77.9,133h-4v-1h8.34v1H79.3L72,143.32l9.13,14.34h2.91v1H65.36v-1h4l-6.16-10.08-6.38,10.08h3.7v1h-8.8v-1h3.7l7.11-11.2L54,133H50.74v-1H69.67v1H65.75Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M85,132H98.46v5.15a9.35,9.35,0,0,1,2.75-4.14q1.85-1.58,5.6-1.57,8.68,0,8.68,9.35v16.86h3.13v1H102.78v-1h2.29v-18.2a12.85,12.85,0,0,0-.45-4.34,1.63,1.63,0,0,0-1.62-1c-1.19,0-2.25.78-3.16,2.35a11.1,11.1,0,0,0-1.38,5.71v15.46h2.41v1H85v-1h3.08V133H85Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M122,155.5q-3.61-3.72-3.62-10.36T122.39,135a15.21,15.21,0,0,1,10.34-3.53q12.71,0,12.2,12.77H129.14v2.18q0,5.49,1.4,8.29a5,5,0,0,0,4.87,2.8q6.5,0,8.52-6.94l1,.16A11.6,11.6,0,0,1,141.1,157q-2.78,2.23-8.32,2.24C128,159.23,124.35,158,122,155.5Zm7.25-12.4H135v-2.75a23.25,23.25,0,0,0-.54-6.18,2.1,2.1,0,0,0-2.12-1.66,2.5,2.5,0,0,0-2.36,1.77,17.13,17.13,0,0,0-.75,6.07Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M164.34,133.58c-1.38,0-2.6.89-3.64,2.69a12.39,12.39,0,0,0-1.57,6.38v15h4v1H145.63v-1h3.08V133h-3.08v-1h13.5v5.38a7.54,7.54,0,0,1,7.5-5.94,6.21,6.21,0,0,1,4.29,1.48,5.36,5.36,0,0,1,1.65,4.2,6.3,6.3,0,0,1-1.23,4.18,4.67,4.67,0,0,1-3.78,1.45,4.29,4.29,0,0,1-3.75-1.68q-1.22-1.68-.31-4.65h2C166.45,134.53,166.05,133.58,164.34,133.58Z"
        transform="translate(-17.84 -20.67)"
      />
      <path
        d="M175.15,159.23a4.5,4.5,0,0,1-3.64-1.43,5.21,5.21,0,0,1-1.2-3.47,5,5,0,0,1,1.26-3.45,4.54,4.54,0,0,1,3.55-1.4,5.3,5.3,0,0,1,3.64,1.18,4.42,4.42,0,0,1,1.35,3.53,5.18,5.18,0,0,1-1.26,3.69A4.83,4.83,0,0,1,175.15,159.23Z"
        transform="translate(-17.84 -20.67)"
      />
    </Svg>
  );
}
