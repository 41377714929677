import React from "react";
import styled from "@emotion/styled";
import { mq } from "../components/MediaQueries";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 7.5em;
  ${mq("medium")} {
    width: 12em;
  }
  min-height: 12em;
  max-height: 100%;
  margin: 0 0 0 1.3em;
  align-self: End;
`;

export default function AboutMeSVG(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 87.76 149.09"
    >
      <path
        class="cls-1"
        d="M84.62,190V172.54h1.06v3.25H103V164.64H85.68V168H84.62V150.42h1.06v3.3h37.07v-3.3h1.07V168h-1.07v-3.31H104.5v11.15h18.25v-3.25h1.07V190h-1.07v-3.3H85.68V190Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M120.65,145.68q-3.72,3.62-10.36,3.62t-10.16-4.07A15.18,15.18,0,0,1,96.6,134.9q0-12.7,12.77-12.21v15.8h2.18c3.66,0,6.43-.47,8.29-1.4a5,5,0,0,0,2.8-4.87q0-6.5-6.94-8.52l.17-1a11.62,11.62,0,0,1,6.27,3.84q2.23,2.78,2.24,8.32Q124.38,142.07,120.65,145.68Zm-12.4-7.25v-5.77h-2.74a23.86,23.86,0,0,0-6.19.53,2.11,2.11,0,0,0-1.65,2.13,2.49,2.49,0,0,0,1.76,2.36,17.2,17.2,0,0,0,6.08.75Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M98.17,123.18h-1V106.94h1v3l18.59-6.11L98.17,97.37v3.75h-1v-7.9h1V96l30.18,10.47q5.27,1.86,7.37,4.15a8,8,0,0,1,2.1,5.6,8.22,8.22,0,0,1-1.51,5.21,4.73,4.73,0,0,1-3.87,1.9,4.84,4.84,0,0,1-3.58-1.23,4.4,4.4,0,0,1-1.23-3.25c0-3.1,1.28-4.65,3.86-4.65a7.38,7.38,0,0,1,2,.34v2a3,3,0,0,0,2.12.11,1.44,1.44,0,0,0,.84-1.51,5.17,5.17,0,0,0-2.26-4.09,24.86,24.86,0,0,0-6.92-3.42l-3.47-1.23v4.93l-25.65,9.58Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M163.81,187.83H146.17v3.08h-1v-3.08h-7.22l-2.58-10.42h9.8v-7.28h1v7.28H165a10.54,10.54,0,0,0,3.87-.53,2.15,2.15,0,0,0,1.23-2.21,3.63,3.63,0,0,0-1.68-2.94,9.71,9.71,0,0,0-4.59-1.65l.11-1a10.84,10.84,0,0,1,6.13,2.47q2.26,2,2.27,6.66t-1.85,7.11Q168.69,187.83,163.81,187.83Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M129.82,169.7V156.21h20.49a9.35,9.35,0,0,1-4.14-2.75q-1.57-1.85-1.57-5.6,0-8.69,9.35-8.68h16.86v-3.13h1v15.84h-1V149.6h-18.2a12.85,12.85,0,0,0-4.34.45,1.63,1.63,0,0,0-1,1.62c0,1.19.78,2.25,2.35,3.16a11.1,11.1,0,0,0,5.71,1.38h15.46V153.8h1v15.9h-1v-3.08h-40v3.08Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M168.65,132.63q-3.72,3.62-10.36,3.62t-10.16-4.06a15.21,15.21,0,0,1-3.53-10.34q0-12.7,12.77-12.2v15.79h2.18c3.66,0,6.43-.47,8.29-1.4a5,5,0,0,0,2.8-4.87q0-6.49-6.94-8.52l.17-1a11.61,11.61,0,0,1,6.27,3.83q2.24,2.77,2.24,8.32C172.38,126.61,171.14,130.23,168.65,132.63Zm-12.4-7.25v-5.77h-2.74a23.28,23.28,0,0,0-6.19.54,2.09,2.09,0,0,0-1.65,2.12,2.49,2.49,0,0,0,1.76,2.36,17.2,17.2,0,0,0,6.08.75Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M146.73,90.36q0,2.07,2.69,3.64a12.49,12.49,0,0,0,6.38,1.56h15v-4h1v17.47h-1V106H146.17v3.08h-1V95.56h5.38a7.58,7.58,0,0,1-4.4-2.8,7.72,7.72,0,0,1-1.54-4.7,6.16,6.16,0,0,1,1.49-4.28,5.37,5.37,0,0,1,4.2-1.66,6.23,6.23,0,0,1,4.17,1.24,4.67,4.67,0,0,1,1.45,3.78,4.31,4.31,0,0,1-1.68,3.75q-1.68,1.2-4.64.31v-2Q146.73,87.79,146.73,90.36Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M168.65,78q-3.72,3.62-10.36,3.61t-10.16-4.06a15.18,15.18,0,0,1-3.53-10.33q0-12.7,12.77-12.21V70.79h2.18c3.66,0,6.43-.46,8.29-1.4a5,5,0,0,0,2.8-4.87q0-6.49-6.94-8.51l.17-1a11.62,11.62,0,0,1,6.27,3.84q2.24,2.77,2.24,8.31Q172.38,74.38,168.65,78Zm-12.4-7.25V65h-2.74a23.86,23.86,0,0,0-6.19.53,2.11,2.11,0,0,0-1.65,2.13A2.48,2.48,0,0,0,147.43,70a16.91,16.91,0,0,0,6.08.76Z"
        transform="translate(-84.62 -41.81)"
      />
      <path
        class="cls-1"
        d="M172.38,46.55a3.88,3.88,0,0,1-1.21,3.13,4.47,4.47,0,0,1-3,1,4.24,4.24,0,0,1-3-1.12,4,4,0,0,1-1.21-3.08,4.59,4.59,0,0,1,1-3.14,3.86,3.86,0,0,1,3-1.17,4.42,4.42,0,0,1,3.19,1.12A4.21,4.21,0,0,1,172.38,46.55ZM161.85,47H157a44,44,0,0,0-11.45,1.82c-4.42,1.21-7.44,1.82-9,1.82a4.18,4.18,0,0,1-3.47-1.32A5,5,0,0,1,132,46.07q0-4.26,4.54-4.26c1.75,0,4.81.66,9.18,2a40.45,40.45,0,0,0,11.37,2h4.76Z"
        transform="translate(-84.62 -41.81)"
      />
    </Svg>
  );
}
