import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 80%;
  max-width: 40em;
`;

export default function Photography(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 259.51 145.38"
    >
      <path
        class="cls-1"
        d="M30.8,89.09q-8.49,1.81-5,18.19l.93,4.32q1.66,7.79,4.85,11.71t7.57,3q6-1.29,8.55-14.08l1-.22,2.23,12.3-.6.13a3.2,3.2,0,0,0-.79-.72,1.15,1.15,0,0,0-.84-.08,32.07,32.07,0,0,0-3.79,1.7,30,30,0,0,1-6.83,2.35q-8.93,1.92-14.86-1.86t-8-13.43q-2.07-9.66,1.92-16t12.65-8.2a25.78,25.78,0,0,1,6.72-.56,27,27,0,0,0,3.93.05,1.18,1.18,0,0,0,.77-.43,3.11,3.11,0,0,0,.42-1l.61-.13,3,11.79-1,.21a33.86,33.86,0,0,0-6.87-7.38A8,8,0,0,0,30.8,89.09Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M64.39,108l1.58-.34-.9-4.21A27.5,27.5,0,0,0,63,96.59a2.27,2.27,0,0,0-2.6-1.3,3.26,3.26,0,0,0-1.67.9,1.66,1.66,0,0,0-.49,1.59A5.44,5.44,0,0,0,59,99.54l1.36-.29a8.18,8.18,0,0,1,1,2.67,3.65,3.65,0,0,1-.78,3.09,5.17,5.17,0,0,1-3.19,1.91q-4.71,1-5.69-3.53-1.44-6.73,10-9.19,6.51-1.4,9.48.29t4.23,7.6l2.38,11.11c.41,1.9,1.14,2.74,2.2,2.51,1.24-.27,1.57-2.3,1-6.11l.84-.12q.81,4.86-.39,7c-.8,1.43-2.41,2.41-4.81,2.92-4.39.94-7.19.13-8.43-2.43a6,6,0,0,1-1.48,3.52,7.38,7.38,0,0,1-3.95,1.88q-8.1,1.74-9.52-4.89-.84-3.88,2-6T64.39,108Zm.34,10.81a1.15,1.15,0,0,0,1.32.58q.86-.18,1.29-1.74a8.35,8.35,0,0,0-.09-4l-1.09-5.09-.38.08q-3.39.73-2.33,5.71l.19.88A13.73,13.73,0,0,0,64.73,118.81Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M90.13,88.69,91.05,93a7.79,7.79,0,0,1,6.51-6.49,8.89,8.89,0,0,1,8,1.83q3.33,2.84,4.81,9.74t-.64,10.78q-2.11,3.9-7.75,5.1a9.33,9.33,0,0,1-4.09.19,5.28,5.28,0,0,1-2.57-1.57l3,14.18,7.06-1.51.22,1-20.26,4.34-.22-1,3-.64-8-37.13-2.9.62-.21-1Zm7.15,2.39c-.64-1.22-1.49-1.72-2.55-1.49s-1.86,1.11-2.41,2.66A10.71,10.71,0,0,0,92.24,98l2.15,10a8,8,0,0,0,1.79,3.79,3,3,0,0,0,3.09,1.06c1.2-.26,1.85-1.24,1.94-2.94a37.18,37.18,0,0,0-1.15-8.57l-.61-2.85A34,34,0,0,0,97.28,91.08Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M114.6,102.51l-3.69-17.25-3,.65-.21-1,3-.64-1.52-7.07,9.65-4.7,2,9.59L128,80.57l.21,1-7.12,1.52,4,18.45a10.41,10.41,0,0,0,1.33,3.67,2.14,2.14,0,0,0,2.42.74,3.64,3.64,0,0,0,2.52-2.26,9.58,9.58,0,0,0,.65-4.83l1-.09a10.82,10.82,0,0,1-1.13,6.51c-1,1.76-3,3-6,3.61s-5.47.55-7.34-.31S115.29,105.69,114.6,102.51Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M167,99.47l-12.48,2.68-1.46-3.87a7.36,7.36,0,0,1-1.88,3.93,9.27,9.27,0,0,1-4.87,2.27q-8.49,1.81-10.44-7.33l-3.54-16.48-3.06.66-.21-1,13.25-2.84,4,18.79a13.15,13.15,0,0,0,1.39,4.22,1.88,1.88,0,0,0,2.09.64,3.12,3.12,0,0,0,2.13-2.23A8.9,8.9,0,0,0,152,94l-3.59-16.76-2.52.54-.21-1,12.71-2.72,5.37,25.07,3-.64Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M181,70.81c-1.36.29-2.35,1.42-3,3.39a12.52,12.52,0,0,0-.2,6.58L181,95.45l3.89-.83.21,1L168,99.26l-.21-1,3-.65-5.16-24.09-3,.65-.21-1,13.19-2.83,1.13,5.26a7.62,7.62,0,0,1,1.82-4.89,7.7,7.7,0,0,1,4.27-2.49,6.13,6.13,0,0,1,4.5.56,5.33,5.33,0,0,1,2.5,3.76,6.26,6.26,0,0,1-.33,4.33,4.67,4.67,0,0,1-3.39,2.22,4.28,4.28,0,0,1-4-.86c-1-.92-1.45-2.42-1.28-4.48l2-.42C183.28,71.3,182.69,70.45,181,70.81Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M189.08,67.48l13.42-2.87,5.37,25.08,3.07-.66.21,1L194.88,93.5l-.21-1,3-.64-5.17-24.09-3.23.69Zm7.46-5.55q-2.91.63-4.45-.59a5.68,5.68,0,0,1-2-3.41,5.19,5.19,0,0,1,.57-3.9,5.47,5.47,0,0,1,3.77-2.29,6.13,6.13,0,0,1,4.44.39,4.83,4.83,0,0,1,2.21,3.36,5.42,5.42,0,0,1-.55,4.1A5.8,5.8,0,0,1,196.54,61.93Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M206.59,63.73l13.2-2.83,1.08,5a9.34,9.34,0,0,1,1.81-4.63c1-1.28,2.7-2.18,5.15-2.7q8.49-1.83,10.45,7.32l3.53,16.48,3.06-.65.22,1-15.5,3.32-.21-1,2.24-.48-3.81-17.8a12.93,12.93,0,0,0-1.35-4.15,1.63,1.63,0,0,0-1.79-.62c-1.17.25-2,1.24-2.6,3a11.13,11.13,0,0,0-.15,5.88L225.16,86l2.36-.5.21,1-15.55,3.33-.22-1,3-.65-5.17-24.09-3,.65Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M261,49.1l1.34-1.26c0-1.18-.59-1.65-1.68-1.42q-2.36.51-1.58,4.12a16,16,0,0,0,1.09,3.2q4.75,1.9,5.69,6.26a8.38,8.38,0,0,1-1.56,7.41,13,13,0,0,1-7.65,4.15,24,24,0,0,1-4.53.52c-1.38,1.36-2,2.3-1.91,2.81s1.33.52,3.67,0l5.58-1.2q12.44-2.65,14.35,6.26a9.3,9.3,0,0,1-2.12,8.45q-3.15,3.61-11.08,5.32-13.53,2.9-14.79-3-.67-3.18,3.28-5.28l3.6.77a12,12,0,0,0,.21,3.11q1,4.7,8,3.2a11.81,11.81,0,0,0,6.23-3,4.8,4.8,0,0,0,1.46-4.44c-.34-1.57-1-2.52-2.08-2.85s-2.95-.2-5.72.4l-5.32,1.14c-2.95.63-5.18.59-6.66-.12a4.55,4.55,0,0,1-2.7-3.23,5.21,5.21,0,0,1,.59-3.91A26.11,26.11,0,0,1,250.81,72q-6.74-.45-8.14-7a8.49,8.49,0,0,1,1.54-7.26q2.41-3.15,8.33-4.42a12.74,12.74,0,0,1,6.37.07,12.56,12.56,0,0,1-.9-2.62c-.44-2-.21-3.64.69-4.78a5.73,5.73,0,0,1,3.38-2.16,4.91,4.91,0,0,1,3.48.37,3.74,3.74,0,0,1,1.86,2.67,3.62,3.62,0,0,1-.39,3,3.47,3.47,0,0,1-2.19,1.42,3.62,3.62,0,0,1-2.46-.25A2.82,2.82,0,0,1,261,49.1Zm-9.41,12.72.63,3a13.51,13.51,0,0,0,1.7,4.85,2.06,2.06,0,0,0,2.2.9,2,2,0,0,0,1.58-1.68,15.77,15.77,0,0,0-.55-5.41l-.58-2.68a18.42,18.42,0,0,0-1.75-5.5,1.94,1.94,0,0,0-2.12-.95A2.13,2.13,0,0,0,251,56.13C250.79,57.15,251,59.05,251.56,61.82Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M19.85,143.28l13.41-2.87,8.59,40.08,3.07-.66.21,1L28.86,184.3l-.21-1,3-.65-8.38-39.1-3.23.7Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M40.28,154.59l13.41-2.87,5.37,25.08,3.07-.66.21,1-16.26,3.48-.21-1,3-.65-5.16-24.09-3.23.69ZM47.73,149q-2.9.63-4.45-.59a5.7,5.7,0,0,1-2-3.4,5.19,5.19,0,0,1,.57-3.9,5.48,5.48,0,0,1,3.78-2.3,6.16,6.16,0,0,1,4.43.39,4.8,4.8,0,0,1,2.21,3.37,5.39,5.39,0,0,1-.55,4.1A5.81,5.81,0,0,1,47.73,149Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M78.38,143.45l1.33-1.26q0-1.77-1.68-1.41c-1.57.33-2.09,1.71-1.58,4.11a17.65,17.65,0,0,0,1.09,3.21q4.75,1.91,5.69,6.25a8.35,8.35,0,0,1-1.56,7.41c-1.66,2-4.21,3.42-7.64,4.16a25,25,0,0,1-4.53.51q-2.09,2.06-1.92,2.82c.11.51,1.33.51,3.67,0l5.59-1.2q12.42-2.66,14.34,6.27a9.25,9.25,0,0,1-2.12,8.44q-3.14,3.61-11.08,5.32-13.53,2.91-14.78-3c-.45-2.11.64-3.88,3.28-5.28l3.6.77a11.18,11.18,0,0,0,.21,3.11q1,4.71,8,3.21a11.7,11.7,0,0,0,6.23-3A4.77,4.77,0,0,0,86,179.51c-.34-1.57-1-2.53-2.07-2.85s-3-.2-5.73.39l-5.31,1.14c-3,.63-5.18.6-6.67-.12a4.54,4.54,0,0,1-2.7-3.23,5.13,5.13,0,0,1,.6-3.9,25.2,25.2,0,0,1,4.1-4.64q-6.73-.44-8.13-7a8.46,8.46,0,0,1,1.53-7.26c1.62-2.1,4.39-3.57,8.34-4.42a13,13,0,0,1,6.37.07,12.76,12.76,0,0,1-.91-2.61c-.44-2.05-.2-3.64.7-4.79a5.64,5.64,0,0,1,3.37-2.15,4.9,4.9,0,0,1,3.49.37,3.76,3.76,0,0,1,1.86,2.66,3.68,3.68,0,0,1-.4,3,3.45,3.45,0,0,1-2.18,1.41,3.56,3.56,0,0,1-2.46-.25A2.74,2.74,0,0,1,78.38,143.45ZM69,156.18l.64,3A13.48,13.48,0,0,0,71.29,164a2.09,2.09,0,0,0,2.2.91,2,2,0,0,0,1.59-1.69,15.87,15.87,0,0,0-.56-5.41L74,155.11a18.52,18.52,0,0,0-1.75-5.49,1.93,1.93,0,0,0-2.13-1,2.14,2.14,0,0,0-1.64,1.81C68.19,151.5,68.37,153.41,69,156.18Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M82.4,129.88l13.19-2.83,4.3,20a9.34,9.34,0,0,1,1.81-4.63c1-1.28,2.7-2.18,5.15-2.7q8.49-1.83,10.45,7.32l3.53,16.48,3.07-.65.21,1-15.5,3.32-.21-1,2.24-.48L106.83,148a12.93,12.93,0,0,0-1.35-4.15,1.63,1.63,0,0,0-1.79-.62c-1.17.25-2,1.24-2.6,3a11.24,11.24,0,0,0-.15,5.88l3.24,15.11,2.36-.5.21,1L91.2,170.94,91,170l3-.65-8.38-39.09-3,.64Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M124.26,155.67l-3.7-17.25-3,.65-.21-1,3-.64-1.51-7.07,9.64-4.7,2.06,9.58,7.11-1.52.22,1-7.12,1.53,3.95,18.45a10.41,10.41,0,0,0,1.33,3.67,2.16,2.16,0,0,0,2.42.74,3.62,3.62,0,0,0,2.52-2.26,9.7,9.7,0,0,0,.66-4.84l1-.09a10.84,10.84,0,0,1-1.12,6.52q-1.5,2.64-6,3.61t-7.34-.32C126.21,160.86,124.94,158.85,124.26,155.67Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M145.68,159.84l-2.1-9.8.65-.14c1.63,3,3.39,4.93,5.27,5.86a9.57,9.57,0,0,0,6.43.62c3.73-.79,5.33-2.38,4.83-4.75a2.55,2.55,0,0,0-1.87-2,18.72,18.72,0,0,0-5.87-.69,21.71,21.71,0,0,1-6.81-1.29c-2.29-.88-3.74-2.75-4.35-5.59a9.25,9.25,0,0,1,1.17-7.38,9.81,9.81,0,0,1,6.47-4,13.87,13.87,0,0,1,5.8.07,4.46,4.46,0,0,0,1.68.1,1.31,1.31,0,0,0,.85-.58,6.23,6.23,0,0,0,.62-1.63l.6-.13,1.8,8.38-.66.14q-4.32-6.51-10.17-5.26a6.76,6.76,0,0,0-3.64,1.72,2.8,2.8,0,0,0-.78,2.55,2,2,0,0,0,.66,1.2c.32.26.57.44.75.56a3,3,0,0,0,.81.31l.91.21c.24,0,.6.09,1.09.13a8.09,8.09,0,0,0,1.07.06,43,43,0,0,1,5.71.47,18.44,18.44,0,0,1,3.48,1c2.3,1,3.79,3,4.45,6.06s.25,5.62-1.24,7.6a11.26,11.26,0,0,1-7,4,15,15,0,0,1-6.91-.18,2.19,2.19,0,0,0-1.13-.22c-.81.17-1.45,1-1.93,2.53Z"
        transform="translate(-14.52 -43.67)"
      />
      <path
        class="cls-1"
        d="M174.37,153.69a4.47,4.47,0,0,1-3.86-.63,5.2,5.2,0,0,1-1.91-3.14,5,5,0,0,1,.51-3.63,4.55,4.55,0,0,1,3.19-2.12,5.32,5.32,0,0,1,3.8.39,4.46,4.46,0,0,1,2.06,3.17,5.26,5.26,0,0,1-.46,3.88A4.85,4.85,0,0,1,174.37,153.69Z"
        transform="translate(-14.52 -43.67)"
      />
    </Svg>
  );
}
