import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 80%;
  max-width: 40em;
`;

export default function GraphicDesign(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 158.76 136.72"
    >
      <path
        class="cls-1"
        d="M116.16,58.42l8.06,37.27-7.66,1.66L90.12,66l7.44,34.38,3-.66.23,1-7.5,1.63-.23-1,3-.65L88.24,64.46l-3,.65-.22-1,15.1-3.27L120.4,85.18l-5.72-26.44-3,.65-.23-1,7.5-1.62.23,1Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M131.38,91.05q-4.26-2.74-5.68-9.28t1.57-10.89q3-4.34,9.77-5.81t10.83,1.07q4,2.53,5.49,9.21,3,14-10.88,17Q135.64,93.79,131.38,91.05Zm12-10.8-1-4.65A37.3,37.3,0,0,0,140.16,68q-1.09-2.31-3.18-1.86a2.79,2.79,0,0,0-1.79,1.1,4.28,4.28,0,0,0-.58,2.51,42.59,42.59,0,0,0,1.13,7.6l.92,4.27a40.51,40.51,0,0,0,1.91,7.09,11.89,11.89,0,0,0,1.17,1.81,2.39,2.39,0,0,0,2.64.81c1.42-.31,2.17-1.33,2.25-3.07A37.92,37.92,0,0,0,143.42,80.25Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M163.55,87.76a4.5,4.5,0,0,1-3.86-.63A5.21,5.21,0,0,1,157.77,84a5,5,0,0,1,.51-3.63,4.51,4.51,0,0,1,3.18-2.12,5.27,5.27,0,0,1,3.8.38,4.39,4.39,0,0,1,2.06,3.16,5.11,5.11,0,0,1-.45,3.88A4.86,4.86,0,0,1,163.55,87.76Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M119.42,124.59l4.06-18.82L118,107l-.19-.87,10.78-2.34.19.88-3.61.78-4.31,20.07,3.92,18.12,3.34-.73.22,1L111,147.68l-.22-1,3.34-.72-3.7-17.08L96.88,111.53l-2.57.56-.19-.88,17.52-3.79.18.88-3.55.77Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M135.84,139.05q-4.32-2.87-5.72-9.36t1.82-10.79a15.16,15.16,0,0,1,9.35-5.63q12.42-2.7,14.63,9.89l-15.43,3.34.46,2.14c.77,3.57,1.81,6.18,3.12,7.8a5,5,0,0,0,5.36,1.71q6.34-1.38,6.85-8.59l1-.05a11.54,11.54,0,0,1-2.43,6.94q-2.24,2.78-7.65,4Q140.15,141.93,135.84,139.05Zm4.47-13.65,5.63-1.22-.58-2.69a23.63,23.63,0,0,0-1.83-5.93,2.11,2.11,0,0,0-2.43-1.17,2.49,2.49,0,0,0-1.92,2.23,17.09,17.09,0,0,0,.55,6.09Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M161.51,137.31l-2.13-9.8.66-.14q2.46,4.45,5.28,5.85a9.58,9.58,0,0,0,6.43.61c3.73-.8,5.33-2.39,4.82-4.77a2.57,2.57,0,0,0-1.87-2,18.88,18.88,0,0,0-5.88-.67,21.89,21.89,0,0,1-6.8-1.28q-3.45-1.32-4.36-5.59a9.21,9.21,0,0,1,1.15-7.38,9.82,9.82,0,0,1,6.45-4.06,13.86,13.86,0,0,1,5.8.06,4.69,4.69,0,0,0,1.69.09,1.29,1.29,0,0,0,.84-.58,6.23,6.23,0,0,0,.62-1.63l.61-.13,1.81,8.38-.66.14q-4.32-6.51-10.18-5.24a6.77,6.77,0,0,0-3.64,1.73,2.78,2.78,0,0,0-.77,2.54,2.06,2.06,0,0,0,.66,1.21c.33.25.58.44.75.55a2.65,2.65,0,0,0,.82.31c.36.1.66.16.9.21s.6.09,1.09.13a8.17,8.17,0,0,0,1.07.06,42,42,0,0,1,5.71.45,18.32,18.32,0,0,1,3.49,1q3.45,1.42,4.46,6c.66,3.08.26,5.62-1.22,7.6a11.31,11.31,0,0,1-7,4,14.84,14.84,0,0,1-6.91-.16,2.19,2.19,0,0,0-1.13-.22c-.81.18-1.44,1-1.92,2.54Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M190.18,131.1a4.48,4.48,0,0,1-3.86-.62,5.2,5.2,0,0,1-1.91-3.14,5,5,0,0,1,.5-3.63,4.53,4.53,0,0,1,3.18-2.12,5.29,5.29,0,0,1,3.81.38,4.42,4.42,0,0,1,2.06,3.16,5.21,5.21,0,0,1-.45,3.88A4.81,4.81,0,0,1,190.18,131.1Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M133.05,180.43l1.13-16.74-5.54-9.75-3.12.68-.23-1,17-3.67.22,1-3.22.69L153,175.77l1-27.31-5.53,1.19-.22-1,10.94-2.36.23,1-3.62.78-1.88,40L149,189.12l-13.46-23-1.76,26.27-4.76,1-22-34.83-2.57.56-.23-1,17.79-3.85.23,1-3.83.83Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M167.38,181.48q-4.26-2.74-5.68-9.28t1.57-10.88q3-4.35,9.77-5.82t10.83,1.07q4,2.53,5.49,9.21,3,14-10.88,17Q171.64,184.22,167.38,181.48Zm12-10.8-1-4.65a37.3,37.3,0,0,0-2.25-7.62q-1.09-2.31-3.18-1.86a2.79,2.79,0,0,0-1.79,1.1,4.28,4.28,0,0,0-.58,2.51,42.59,42.59,0,0,0,1.13,7.6l.93,4.27a39.94,39.94,0,0,0,1.9,7.09,11.41,11.41,0,0,0,1.17,1.81,2.39,2.39,0,0,0,2.64.81c1.42-.31,2.17-1.33,2.25-3.07A37.92,37.92,0,0,0,179.42,170.68Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M204.71,150.25l-.22-1,14.13-3.06.21,1-2.24.48,8.91,17.38.88-19.5-3.77.82-.22-1,7.39-1.6.21,1-2.29.5-1.15,26.49-7.5,1.62-8.39-15.77-1.51,17.92-7.34,1.58-13.52-23.31-2.47.53-.21-1,16.59-3.59.21,1-3.28.71L208.19,169l1.17-13.66-2.63-5.5Z"
        transform="translate(-85.01 -56.73)"
      />
      <path
        class="cls-1"
        d="M239.81,169.47a4.52,4.52,0,0,1-3.86-.62,5.25,5.25,0,0,1-1.91-3.14,4.94,4.94,0,0,1,.51-3.64,4.57,4.57,0,0,1,3.17-2.12,5.4,5.4,0,0,1,3.81.38,4.44,4.44,0,0,1,2.06,3.17,5.21,5.21,0,0,1-.45,3.88A4.81,4.81,0,0,1,239.81,169.47Z"
        transform="translate(-85.01 -56.73)"
      />
    </Svg>
  );
}
