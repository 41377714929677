import React from "react";
import styled from "@emotion/styled";

const Svg = styled.svg`
  fill: var(--dark-pri);
  width: 80%;
  max-width: 40em;
`;

export default function UXUI(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 249.46 146.81"
      {...props}
    >
      <path
        class="cls-1"
        d="M24.6,64.13,53.07,58l3,11.27-1,.21q-3.3-5.47-6.42-7c-2.07-1-4.86-1.19-8.37-.43l-1.53.33L42.6,80.14l2.46-.53a5.81,5.81,0,0,0,4-2.86A11,11,0,0,0,50.31,71l.82-.17L54.62,87l-.93.2A13.35,13.35,0,0,0,50,81.87,5.55,5.55,0,0,0,45.37,81l-2.46.53,3.59,16.7,6.79-1.46.26,1.2-20.7,4.46-.25-1.21,3.23-.69L28.09,64.64l-3.23.7Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M64.84,92.48q-4.26-2.74-5.67-9.29c-.93-4.36-.41-8,1.59-10.88s5.25-4.83,9.77-5.8,8.14-.62,10.83,1.07,4.52,4.77,5.48,9.22q3,14-10.9,17Q69.1,95.23,64.84,92.48ZM76.9,81.69,75.9,77a38,38,0,0,0-2.25-7.62c-.73-1.55-1.79-2.17-3.18-1.87a2.79,2.79,0,0,0-1.79,1.1,4.26,4.26,0,0,0-.58,2.5,42.33,42.33,0,0,0,1.12,7.61L70.14,83a40.46,40.46,0,0,0,1.9,7.1,13.37,13.37,0,0,0,1.16,1.81,2.37,2.37,0,0,0,2.64.8c1.42-.3,2.18-1.32,2.26-3.06A38.35,38.35,0,0,0,76.9,81.69Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M102.93,61.71q-2,.43-3,3.39a12.38,12.38,0,0,0-.19,6.57l3.16,14.68,3.89-.84.21,1L89.93,90.17l-.22-1,3-.65L87.54,64.45l-3,.65-.21-1,13.19-2.84,1.13,5.26a7.52,7.52,0,0,1,6.09-7.38,6.2,6.2,0,0,1,4.5.54,5.38,5.38,0,0,1,2.5,3.76,6.29,6.29,0,0,1-.33,4.34A4.7,4.7,0,0,1,108,70a4.31,4.31,0,0,1-4-.85q-1.53-1.39-1.28-4.48l2-.43Q105.45,61.18,102.93,61.71Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M111.09,58.35l13.2-2.84,1.08,5q.6-6,6.76-7.29t8.75,4.3q.65-6.33,6.78-7.65,8-1.73,10,7.41l3.55,16.48,3.06-.66.22,1-15.17,3.26-.21-1L151,76l-3.83-17.79q-.9-4.15-1.77-4.72a1.24,1.24,0,0,0-.94-.14c-1.06.23-1.84,1.14-2.33,2.74a11.16,11.16,0,0,0,0,5.68l3.32,15.44,2-.43.21,1-14.07,3-.21-1,1.92-.41-3.83-17.79q-.9-4.17-1.76-4.72a1.22,1.22,0,0,0-1-.14c-1,.22-1.78,1.19-2.26,2.92a12,12,0,0,0,0,5.82l3.25,15.11,2-.42.21,1L116.7,84.41l-.21-1,3-.65-5.18-24.08-3,.64Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M50,104.55a2.71,2.71,0,0,0-2.4,2.15,14.41,14.41,0,0,0,.42,5.15l1.89,8.76,7.22-1.55.22,1-7.23,1.56,5.19,24.09L62,144.25l.22,1-19.88,4.28-.21-1,3-.65L40,123.79l-3.18.68-.21-1,3.18-.69,0-.21Q38,114.7,40.14,110t9.2-6.24q4.65-1,7.07.39a5.71,5.71,0,0,1,2.94,3.81,4.87,4.87,0,0,1-.48,3.89,4.75,4.75,0,0,1-3.08,1.92,4.56,4.56,0,0,1-3.43-.38,3.38,3.38,0,0,1-1.65-2.22,11.44,11.44,0,0,1-.26-2.78l2.08-.45C52.73,105.27,51.89,104.15,50,104.55Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M64.13,141.73q-4.26-2.74-5.67-9.29T60,121.56q3-4.34,9.78-5.8t10.83,1.08q4,2.53,5.48,9.21,3,14-10.9,17Q68.39,144.48,64.13,141.73Zm12.06-10.79-1-4.65a38.09,38.09,0,0,0-2.24-7.62c-.73-1.54-1.79-2.17-3.18-1.87A2.8,2.8,0,0,0,68,117.91a4.22,4.22,0,0,0-.58,2.5,42.24,42.24,0,0,0,1.12,7.6l.92,4.27a39.24,39.24,0,0,0,1.9,7.1,13.37,13.37,0,0,0,1.16,1.81,2.39,2.39,0,0,0,2.64.81c1.42-.31,2.18-1.33,2.26-3.07A38.35,38.35,0,0,0,76.19,130.94Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M80.11,98.42l13.41-2.89,8.63,40.08,3.06-.66.22,1-16.26,3.5-.22-1,3-.65L83.55,98.71l-3.23.7Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M97.32,94.72l13.41-2.89,8.63,40.07,3.07-.66.21,1-16.26,3.5-.21-1,3-.64L100.76,95l-3.23.69Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M125.65,128.49q-4.26-2.75-5.67-9.29t1.58-10.88q3-4.35,9.78-5.8t10.83,1.07q4,2.55,5.48,9.22,3,14-10.9,16.95Q129.9,131.25,125.65,128.49Zm12-10.79-1-4.65a38.09,38.09,0,0,0-2.24-7.62c-.73-1.55-1.79-2.17-3.18-1.87a2.84,2.84,0,0,0-1.8,1.1,4.42,4.42,0,0,0-.58,2.5,42.55,42.55,0,0,0,1.13,7.61l.91,4.27a40.46,40.46,0,0,0,1.9,7.1A12.42,12.42,0,0,0,134,128a2.37,2.37,0,0,0,2.64.8c1.42-.3,2.17-1.32,2.26-3.06A39.38,39.38,0,0,0,137.7,117.7Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M163,97.3l-.21-1,14.13-3,.21,1-2.25.48,8.9,17.39.9-19.5-3.77.82-.22-1,7.39-1.59.22,1-2.3.49-1.18,26.49-7.5,1.61L169,104.67l-1.52,17.92-7.34,1.58-13.5-23.33-2.46.53-.22-1,16.59-3.57.21,1-3.28.7,9,17.53,1.19-13.66L165,96.87Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M194.25,117.38l-2.11-9.79.66-.15q2.44,4.47,5.27,5.86a9.6,9.6,0,0,0,6.44.62q5.58-1.2,4.81-4.76a2.55,2.55,0,0,0-1.86-2,19.15,19.15,0,0,0-5.88-.68,21.89,21.89,0,0,1-6.8-1.28c-2.29-.89-3.74-2.75-4.36-5.6a9.3,9.3,0,0,1,1.16-7.38,9.89,9.89,0,0,1,6.46-4,13.87,13.87,0,0,1,5.8.07,4.69,4.69,0,0,0,1.69.09,1.29,1.29,0,0,0,.84-.58,6.53,6.53,0,0,0,.63-1.63l.6-.13,1.8,8.38-.65.14q-4.33-6.51-10.18-5.25A6.69,6.69,0,0,0,194.93,91a2.75,2.75,0,0,0-.77,2.54,2,2,0,0,0,.66,1.2c.32.26.57.44.75.56a2.73,2.73,0,0,0,.81.31l.9.21c.24,0,.6.09,1.09.13a8.24,8.24,0,0,0,1.07.06,43.62,43.62,0,0,1,5.72.46,18.44,18.44,0,0,1,3.48,1c2.3,1,3.79,3,4.45,6.06s.25,5.61-1.23,7.59a11.31,11.31,0,0,1-7,4,14.85,14.85,0,0,1-6.91-.17,2.19,2.19,0,0,0-1.13-.22c-.8.17-1.44,1-1.92,2.53Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M60.13,151.48a2.73,2.73,0,0,0-2.4,2.15,14.42,14.42,0,0,0,.42,5.15L60,167.54,67.27,166l.21,1-7.23,1.55,5.19,24.09,6.68-1.44.21,1-19.87,4.28-.22-1,3-.65-5.18-24.09-3.18.69-.21-1,3.18-.68,0-.22q-1.7-7.89.44-12.61t9.19-6.25q4.65-1,7.08.4a5.68,5.68,0,0,1,2.93,3.81,4.85,4.85,0,0,1-.48,3.88,4.71,4.71,0,0,1-3.08,1.92,4.56,4.56,0,0,1-3.43-.37,3.42,3.42,0,0,1-1.65-2.23,11.39,11.39,0,0,1-.26-2.78l2.08-.45Q62.93,150.89,60.13,151.48Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M105.83,185l-12.48,2.68-1.46-3.86A7.33,7.33,0,0,1,90,187.69,9.35,9.35,0,0,1,85.15,190q-8.49,1.83-10.46-7.31l-3.54-16.48-3.07.66-.21-1L81.12,163l4,18.78A13.15,13.15,0,0,0,86.55,186a1.88,1.88,0,0,0,2.09.64,3.12,3.12,0,0,0,2.13-2.23,8.91,8.91,0,0,0,.08-4.94l-3.61-16.76-2.52.54-.21-1,12.7-2.74,5.4,25.08,3-.65Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M101.25,158.67l13.2-2.85,1.08,5a9.34,9.34,0,0,1,1.81-4.63c1-1.28,2.7-2.18,5.14-2.71q8.49-1.83,10.46,7.32l3.55,16.48,3.06-.66.21,1L124.27,181l-.21-1,2.24-.48-3.83-17.79a12.83,12.83,0,0,0-1.35-4.15,1.63,1.63,0,0,0-1.79-.62c-1.17.25-2,1.24-2.6,3a11.1,11.1,0,0,0-.14,5.87l3.25,15.11,2.36-.51.21,1-15.55,3.34-.21-1,3-.65L104.47,159l-3,.65Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M149.08,148.83a3.66,3.66,0,0,0-2.88,3.37c-.32,1.9,0,4.87.83,8.93l.62,2.84c.78,3.62,1.73,6.12,2.87,7.52s2.79,1.86,4.95,1.4a6.67,6.67,0,0,0,4.67-3.3,10.76,10.76,0,0,0,1.39-5.51l1-.11q0,4.93-2.37,7.62t-7.37,3.82q-6.35,1.36-10.48-1.33t-5.55-9.28q-1.43-6.6,1.67-11c2.06-2.93,5.4-4.88,10-5.87q4.88-1,7.86.28a5.83,5.83,0,0,1,3.59,4.18,6,6,0,0,1-.34,4.43,4.53,4.53,0,0,1-3.11,2,5.19,5.19,0,0,1-3.72-.4,4.36,4.36,0,0,1-2.05-3.11,7.6,7.6,0,0,1-.15-2l1.81-.39a6.27,6.27,0,0,0,0-2.05A2.38,2.38,0,0,0,149.08,148.83Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M165.62,163.88l-3.71-17.24-3,.65-.22-1,3-.65-1.52-7.06,9.64-4.71,2.06,9.58,7.11-1.53.22,1-7.12,1.54,4,18.45a10.63,10.63,0,0,0,1.33,3.66,2.16,2.16,0,0,0,2.43.74,3.64,3.64,0,0,0,2.52-2.26,9.83,9.83,0,0,0,.65-4.84l.95-.09a10.87,10.87,0,0,1-1.12,6.52c-1,1.76-3,3-6,3.61s-5.47.55-7.34-.31S166.3,167.06,165.62,163.88Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M180.1,141.69l13.41-2.89,5.4,25.08,3.07-.66.21,1-16.26,3.5-.21-1,3-.65L183.54,142l-3.23.7Zm7.45-5.55q-2.9.62-4.45-.59a5.73,5.73,0,0,1-2-3.41,5.19,5.19,0,0,1,.56-3.9,5.48,5.48,0,0,1,3.78-2.3,6.16,6.16,0,0,1,4.43.39,4.76,4.76,0,0,1,2.22,3.36,5.37,5.37,0,0,1-.55,4.1A5.82,5.82,0,0,1,187.55,136.14Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M205.22,160.46q-4.25-2.76-5.66-9.29c-.94-4.37-.41-8,1.58-10.89s5.25-4.82,9.78-5.8,8.13-.61,10.83,1.08,4.52,4.77,5.48,9.22q3,14-10.9,16.95Q209.47,163.2,205.22,160.46Zm12.06-10.79-1-4.65a38.45,38.45,0,0,0-2.24-7.63q-1.11-2.31-3.18-1.86a2.81,2.81,0,0,0-1.8,1.1,4.32,4.32,0,0,0-.58,2.5,43.27,43.27,0,0,0,1.12,7.61l.92,4.27a40.46,40.46,0,0,0,1.9,7.1,12.42,12.42,0,0,0,1.17,1.81,2.36,2.36,0,0,0,2.63.8c1.43-.3,2.18-1.32,2.26-3.06A38.35,38.35,0,0,0,217.28,149.67Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M224.75,132.08l13.19-2.84,1.09,5a9.21,9.21,0,0,1,1.81-4.63c1-1.28,2.7-2.19,5.14-2.71q8.49-1.83,10.46,7.31L260,150.73l3.06-.66.21,1-15.49,3.33-.21-1,2.24-.49L246,135.13a12.93,12.93,0,0,0-1.35-4.15,1.63,1.63,0,0,0-1.79-.61c-1.17.25-2,1.24-2.6,3a11.1,11.1,0,0,0-.14,5.87l3.25,15.11,2.36-.5.21,1-15.55,3.35-.21-1,3-.64L228,132.42l-3,.64Z"
        transform="translate(-24.6 -49.63)"
      />
      <path
        class="cls-1"
        d="M270.11,150.15a4.44,4.44,0,0,1-3.86-.63,5.17,5.17,0,0,1-1.91-3.14,5,5,0,0,1,.51-3.63,4.5,4.5,0,0,1,3.18-2.11,5.29,5.29,0,0,1,3.81.38,4.43,4.43,0,0,1,2.05,3.16,5.2,5.2,0,0,1-.45,3.88A4.78,4.78,0,0,1,270.11,150.15Z"
        transform="translate(-24.6 -49.63)"
      />
    </Svg>
  );
}
